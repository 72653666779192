<template>
  <div id="app" class="flex flex-col p-2 items-center">

    <nav class="rounded-3xl p-4 flex justify-center gap-8 max-w-max-content sticky top-4">

      <router-link active-class="current" to="/">Home</router-link>
      <router-link active-class="current" to="/websites">Websites</router-link>
      <router-link active-class="current" to="/mobile-app">Mobile App</router-link>
    </nav>

    <div class="w-full flex justify-center">
      <router-view></router-view>
    </div>
    <div class="container footer flex flex-col p-8 w-full">
      <ul class="flex justify-between mobile-column gap-2">
        <li class="flex gap-1"><img class="icon" :src="require(`@/assets/icons/user.svg`)">Alexandros Ploumis</li>
        <li class="flex gap-1"><img class="icon" :src="require(`@/assets/icons/terminal.svg`)">Full Stack Developer</li>
        <li class="flex gap-1"><img class="icon" :src="require(`@/assets/icons/call.svg`)"> <a href="tel:+306947628012">+30 6947628012</a></li>
        <li class="flex gap-1"><img class="icon" :src="require(`@/assets/icons/email.svg`)"><a href="mailto:ploumisalexandrosgr@gmail.com">ploumisalexandrosgr@gmail.com</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
  nav{
    background: #0D112F;
    box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.9);
    z-index: 2;
  }

  nav a{
    color: #F7F7F7;
    letter-spacing: 2px;
  }



  nav a.current{
    font-weight: bold;
  }

  nav a:hover{
    color: #F7F7F7;
    font-weight: bold;
  }

  @media only screen and (max-width: 600px) {
    nav.gap-8{
      gap: 1.5rem;
    }

    nav a{
      font-size: 14px;
    }


    .footer.p-8{
      padding: 1rem 0rem;
    }
  }


</style>