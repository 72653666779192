<template>
  <div class="container flex flex-col justify-center items-center p-12 gap-16">
    <div class="flex gap-8 flex-wrap">
      <div class="section p-8 flex flex-col gap-4">
        <h1 class="text-4xl my-4">About Me</h1>
        <p>Welcome to my portfolio! My name is <b>Alexandros Ploumis</b> I am a passionate and experienced Full Stack Web Developer with a robust knowledge of various technologies and platforms. With a focus on delivering high-quality, user-centric web solutions, I specialize in both back-end and front-end development.</p>
        <p>I thrive in a collaborative environment and am always eager to take on new challenges that help me grow both personally and professionally. My goal is to create seamless and engaging web experiences that drive results and exceed client expectations.</p>
      
      </div>  
      <div class="section p-8 flex flex-col gap-4">
        <h1 class="text-4xl my-4">Education</h1>
        <p>I completed my Bachelor's degree in Applied Informatics from the University of Macedonia. During my studies, I gained comprehensive knowledge and practical skills in various areas of informatics, including software development, database management, and information systems.</p>

      </div>  
      <div class="section p-8 flex flex-col gap-4">
        <h3 class="text-2xl  my-3">CMS Knowledge</h3>
        <ul class="flex gap-4 mb-4 mobile-column">
          <li class="flex flex-col gap-4">
            <img class="logo" :src="require(`@/assets/icons/wordpress_1.svg`)">
            <div class="li-content">
              <h5 class="text-lg">WordPress</h5>
              <p>Skilled in developing and customizing e-commerce solutions using OpenCart.</p>
            </div>
          </li>
          <li class="flex flex-col gap-4">
            <img class="logo" :src="require(`@/assets/icons/opencart.svg`)">
            <div class="li-content">
              <h5 class="text-lg">OpenCart</h5>
              <p>Experienced in creating and customizing WordPress themes and plugins to meet diverse client needs.</p>
            </div>
          </li>
          <li class="flex flex-col gap-4">
            <img class="logo" :src="require(`@/assets/images/shopranos_1.png`)">
            <div class="li-content">
              <h5 class="text-lg">Shopranos</h5>
              <p>Experienced in customizing a Shopranos eshop, which is a newly developed cms by SOFTONE.</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="section p-8 flex flex-col w-50">
        <h3 class="text-2xl  my-3">Back-End Knowledge</h3>
        <ul class="flex flex-col gap-4 mb-4">
          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/icons/php.svg`)">
            <div class="li-content">
              <h5 class="text-lg">PHP</h5>
              <p>Expertise in server-side scripting to build dynamic and interactive web applications.</p>
            </div>
          </li>
          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/icons/database.svg`)">
            <div class="li-content">
              <h5 class="text-lg">SQL</h5>
              <p>Proficient in database design, optimization, and management to ensure efficient data storage and retrieval.</p>
            </div>
          </li>
          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/icons/algorithm.svg`)">
            <div class="li-content">
              <h5 class="text-lg">MVC Architecture</h5>
              <p>Strong understanding of the Model-View-Controller (MVC) design pattern for structuring web applications.</p>
            </div>
          </li>
          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/icons/api.svg`)">
            <div class="li-content">
              <h5 class="text-lg">RESTful API Development</h5>
              <p>Skilled in creating and integrating RESTful APIs for seamless communication between client and server.</p>
            </div>
          </li>
          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/icons/github.svg`)">
            <div class="li-content">
              <h5 class="text-lg">Version Control</h5>
              <p>Proficient in using Git for version control, enabling effective collaboration and code management.</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="section p-8 flex flex-col w-50">
        <h3 class="text-2xl  my-3">Front-End Knowledge</h3>
        <ul class="flex flex-col gap-4 mb-4">
          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/icons/java-script.svg`)">
            <div class="li-content">
              <h5 class="text-lg">JavaScript</h5>
              <p>Proficient in writing efficient and maintainable code for dynamic web applications.</p>
            </div>
          </li>
          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/icons/jquery.svg`)">
            <div class="li-content">
              <h5 class="text-lg">jQuery</h5>
              <p>Proficient in database design, optimization, and management to ensure efficient data storage and retrieval.</p>
            </div>
          </li>
          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/icons/vue.svg`)">
            <div class="li-content">
              <h5 class="text-lg">Vue</h5>
              <p>Skilled in building progressive, reactive web applications with Vue.js.</p>
            </div>
          </li>
          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/icons/html-5.svg`)">
            <div class="li-content">
              <h5 class="text-lg">HTML</h5>
              <p>Strong understanding of HTML5 for structuring web content effectively.</p>
            </div>
          </li>
          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/icons/css-3.svg`)">
            <div class="li-content">
              <h5 class="text-lg">CSS, Bootstrap, Tailwind</h5>
              <p>Expertise in CSS3 for styling and designing web pages to create visually appealing and responsive layouts.</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="section p-8 flex flex-col">
        <h1 class="text-4xl my-4">Custom Software Development</h1>
        <p>In addition to web development, I specialize in custom software development tailored to meet specific business needs. Leveraging the latest technologies and best practices, I create scalable and robust solutions that enhance productivity and streamline operations. My expertise includes:</p>
        <div class="flex gap-4 mobile-column">
          <div class="flex flex-col w-50 mt-4">
            <img class="logo mt-2" :src="require(`@/assets/images/laravel.png`)">
            <h5 class="text-lg mt-2 mb-1">Laravel Development</h5>
            <ul class="mb-4 ml-2">
              <li><p>Building custom web applications using the Laravel framework</p></li>
              <li><p>Developing RESTful APIs for seamless integration with other systems</p></li>
              <li><p>Implementing advanced features like user authentication, authorization, and data validation</p></li>
              <li><p>Ensuring high performance and security through best coding practices and testing</p></li>
            </ul>
          </div>
          <div class="flex flex-col w-50 mt-4">
            <img class="logo mt-2" :src="require(`@/assets/images/android.png`)">
            <h5 class="text-lg mt-2 mb-1">Android Studio Development</h5>
            <ul class="mb-4 ml-2">
              <li><p>Designing and developing native Android applications</p></li>
              <li><p>Creating intuitive and user-friendly mobile interfaces</p></li>
              <li><p>Integrating third-party APIs and services to extend app functionality</p></li>
              <li><p>Providing ongoing maintenance and support to ensure app stability and performance</p></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="section p-8 flex flex-col">
        <h3 class="text-2xl  my-3">General Knowledge</h3>
        <ul class="flex flex-col gap-4 mb-4">
          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/icons/java.svg`)">
            <div class="li-content">
              <h5 class="text-lg">Java</h5>
              <p>Proficient in building robust and scalable applications using Java, with experience in both web and desktop environments.</p>
            </div>
          </li>

          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/icons/c-sharp.svg`)">
            <div class="li-content">
              <h5 class="text-lg">C#</h5>
              <p>Skilled in developing applications with C#, including enterprise-level solutions and game development with Unity.</p>
            </div>
          </li>

          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/icons/node-js.svg`)">
            <div class="li-content">
              <h5 class="text-lg">Node.js</h5>
              <p>Experienced in building fast and scalable server-side applications using Node.js, with a focus on asynchronous programming and API development.</p>
            </div>
          </li>

          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/icons/node-js.svg`)">
            <div class="li-content">
              <h5 class="text-lg">Express</h5>
              <p>Specialized in creating efficient and modular web applications using Express, a minimal and flexible Node.js web application framework.</p>
            </div>
          </li>

          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/icons/unity.svg`)">
            <div class="li-content">
              <h5 class="text-lg">Unity</h5>
              <p>Expertise in developing immersive and interactive 3D and 2D games using the Unity game engine, leveraging C# for scripting.</p>
            </div>
          </li>
          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/icons/github_1.svg`)">
            <div class="li-content">
              <h5 class="text-lg">GitHub</h5>
              <p>Experienced in using GitHub for version control, collaboration, and continuous integration, facilitating efficient and organized development workflows.</p>
            </div>
          </li>
          <li class="flex gap-4 items-center">
            <img :src="require(`@/assets/images/go.png`)">
            <div class="li-content">
              <h5 class="text-lg">Go</h5>
              <p>Junior-level experience in developing applications using Go (Golang), with a focus on learning and applying best practices in concurrent programming and efficient resource management.</p>
            </div>
          </li>

        </ul>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'HomePage'
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>