<template>
    <div class="flex flex-col justify-center items-center p-12 gap-16 container">
        <div class="row flex gap-8 flex-wrap">
            <div class="section p-8 flex flex-col gap-4">
                <h1>Some of the websites I have Worked on</h1>
            </div>
        </div>
        <div class="flex gap-8 flex-wrap w-full">
            <div class="section p-8 flex flex-col gap-4 w-33">
                <img class="logo-x2" :src="require(`@/assets/icons/wordpress_1.svg`)">
                <p>I am a seasoned full-stack web developer with extensive experience in WordPress, having completed over 50 projects ranging from simple informational websites to complex e-commerce platforms. My expertise includes creating custom themes, developing bespoke plugins, and tailoring solutions to meet unique business needs. Whether building a straightforward site or an advanced eShop, I focus on delivering high-performance, secure, and scalable web solutions.</p>
                <small>*These are not all the websites I have worked on</small>
            </div>
            <div class="section p-8 flex flex-col gap-4 w-33">
                <h1 class="text-center text-2xl text-2xl">MyReptileShop</h1>
                <div class="website-container">
                    <img class="website-img" :src="require(`@/assets/websites/reptile.png`)">
                </div>
            </div>
            <div class="section p-8 flex flex-col gap-4 w-33">
                <h1 class="text-center text-2xl">GetAwaysGreece</h1>
                <div class="website-container">
                    <img class="website-img" :src="require(`@/assets/websites/getaways.png`)">
                </div>
            </div>
            <div class="section p-8 flex flex-col gap-4 w-33">
                <h1 class="text-center text-2xl">Parent-Ed</h1>
                <div class="website-container">
                    <img class="website-img" :src="require(`@/assets/websites/parented.png`)">
                </div>
            </div>
            <div class="section p-8 flex flex-col gap-4 w-33">
                <h1 class="text-center text-2xl">RentitPapas</h1>
                <div class="website-container">
                    <img class="website-img" :src="require(`@/assets/websites/rentit.png`)">
                </div>
            </div>
            <div class="section p-8 flex flex-col gap-4 w-33">
                <h1 class="text-center text-2xl">SexologySupervisors</h1>
                <div class="website-container">
                    <img class="website-img" :src="require(`@/assets/websites/ioss.png`)">
                </div>
            </div>
        </div>
        <div class="flex gap-8 flex-wrap w-full">
            <div class="section p-8 flex flex-col gap-4 w-33">
                <img class="logo-x2" :src="require(`@/assets/icons/opencart.svg`)">
                <p>I have significant experience in OpenCart, having worked on over 10 large-scale projects. Nearly all of these projects required integration with ERP systems via API requests to facilitate seamless data communication. Throughout these projects, I have developed numerous custom extensions and features to meet specific business requirements. My focus is on delivering high-quality, efficient, and scalable e-commerce solutions.</p>
                <small>*These are not all the websites I have worked on</small>
            </div>
            <div class="section p-8 flex flex-col gap-4 w-33">
                <h1 class="text-center text-2xl text-2xl">Edward</h1>
                <div class="website-container">
                    <img class="website-img" :src="require(`@/assets/websites/edward.png`)">
                </div>
            </div>
            <div class="section p-8 flex flex-col gap-4 w-33">
                <h1 class="text-center text-2xl">MarmaladyClothingBar</h1>
                <div class="website-container">
                    <img class="website-img" :src="require(`@/assets/websites/marmalady.png`)">
                </div>
            </div>
            <div class="section p-8 flex flex-col gap-4 w-33">
                <h1 class="text-center text-2xl">Pinup-Fashion</h1>
                <div class="website-container">
                    <img class="website-img" :src="require(`@/assets/websites/pinup.png`)">
                </div>
            </div>
            <div class="section p-8 flex flex-col gap-4 w-33">
                <h1 class="text-center text-2xl">Energiers</h1>
                <div class="website-container">
                    <img class="website-img" :src="require(`@/assets/websites/energiers.png`)">
                </div>
            </div>
            <div class="section p-8 flex flex-col gap-4 w-33">
                <h1 class="text-center text-2xl">Dazzeal</h1>
                <div class="website-container">
                    <img class="website-img" :src="require(`@/assets/websites/dazzeal.png`)">
                </div>
            </div>
        </div>
        <div class="flex gap-8 flex-wrap w-full">
            <div class="section p-8 flex flex-col gap-4 w-33">
                <img class="logo-x2" :src="require(`@/assets/images/shopranos_1.png`)">
                <p>I wave worked on a couple of shopranos projects. The concept of shopranos is that it is a cms with an ERP already connected to it, so a developer only needs to customize the front of the eshop with vuejs and a number of custom components. It is primarly focused on B2B eshops.</p>
            </div>
            <div class="section p-8 flex flex-col gap-4 w-33">
                <h1 class="text-center text-2xl text-2xl">B2B CoffeeLab</h1>
                <div class="website-container">
                    <img class="website-img" :src="require(`@/assets/websites/coffeelab.png`)">
                </div>
            </div>
            <div class="section p-8 flex flex-col gap-4 w-33">
                <h1 class="text-center text-2xl text-2xl">NGP Sealnet</h1>
                <div class="website-container">
                    <img class="website-img" :src="require(`@/assets/websites/ngp.png`)">
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'WebSites'
  };
  </script>
  
  <style scoped>
 

  @media only screen and (min-width: 600px) {

    .website-container{
        height: 560px;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.9);
        position: relative;
    }

    .website-container .website-img{
        position: absolute;
        top: 0;
        transition: transform 3s ease;
    }

    .website-container:hover .website-img {
            transform: translateY(calc(-100% + 560px));
        }
  }

  @media only screen and (max-width: 600px) {

    .website-container{
        height: 560px;
        overflow-y: auto;
        border-radius: 8px;
        box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.9);
        position: relative;
    }

    .website-container::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    .website-container::-webkit-scrollbar
    {
        width:2px;
        background-color: #F5F5F5;
    }

    .website-container::-webkit-scrollbar-thumb
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #373C4C;
    }

}



  </style>
  