<template>
  <div class="contact-page flex flex-col justify-center items-center p-16 gap-12 w-full">
        <div class="row flex gap-8 flex-wrap w-full justify-center">
            <div class="section p-8 flex flex-col gap-4 w-33">
              <h1 class="text-4xl my-4">Contact Page</h1>
              <p>Got something on your mind? Don't hesitate to drop me a line! I'll get back to you real quick. Let's get in contact soon!</p>
              <form @submit.prevent="submitForm">
                <div class="mb-4">
                  <label for="email" class="block text-sm font-medium ">Email</label>
                  <input
                    type="email"
                    id="email"
                    v-model="form.email"
                    class="mt-1 block w-full px-3 py-2 rounded-lg"
                    required
                  />
                </div>
                <div class="mb-4">
                  <label for="phone" class="block text-sm font-medium ">Phone</label>
                  <input
                    type="tel"
                    id="phone"
                    v-model="form.phone"
                    class="mt-1 block w-full px-3 py-2 rounded-lg"
                  />
                </div>
                <div class="mb-4">
                  <label for="subject" class="block text-sm font-medium ">Subject</label>
                  <input
                    type="text"
                    id="subject"
                    v-model="form.subject"
                    class="mt-1 block w-full px-3 py-2 rounded-lg"
                    required
                  />
                </div>
                <div class="mb-4">
                  <label for="message" class="block text-sm font-medium ">Message</label>
                  <textarea
                    id="message"
                    v-model="form.message"
                    rows="4"
                    class="mt-1 block w-full px-3 py-2 rounded-lg"
                    required
                  ></textarea>
                </div>
                <div class="text-right">
                  <button
                    type="submit"
                    class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactPage',
  data() {
    return {
      form: {
        email: '',
        phone: '',
        subject: '',
        message: '',
      },
    };
  },
  methods: {
    submitForm() {
      // Handle form submission logic here
      console.log('Form submitted', this.form);
    },
  },
};
</script>

<style scoped>
  h1{
    margin-bottom: 0;
  }

  form input,form textarea{
    outline-color: #7690B0;
    border-radius: 20px;
    border: none;
    color: #15253D;
  }

  form input:focus,form textarea:focus{
    outline-color: #7690B0;
  }

  @media only screen and (max-width: 600px) {

    .contact-page.p-16{
      padding: 1rem;
    }
  }


</style>
