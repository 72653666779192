<template>

  <div class="container flex flex-col justify-center items-center p-12 gap-16 w-full">
    <div class="flex gap-8 flex-wrap">
      <div class="section p-8 flex flex-col gap-4">
        <h1 class="text-2xl">Privacy Policy for <i>Δίπλωμα Αυτοκινήτου Σήματα ΚΟΚ</i></h1>
        <div class="policy-row mt-1 mb-1">
          <h5>Data Collection and Usage</h5>
          <p><b>No User Data Collection:</b><i>Δίπλωμα Αυτοκινήτου Σήματα ΚΟΚ</i> does not collect any personal
            information from its users. We do not require registration or collect any user data during app usage.</p>
        </div>
        <div class="policy-row mt-1 mb-1">
          <h5>Data Security</h5>
          <p><b>Data Security Measures:</b>While no user data is collected, <i>Δίπλωμα Αυτοκινήτου Σήματα ΚΟΚ</i> is
            committed to ensuring the security of user information. The app does not store any user data locally or on
            external servers.</p>
        </div>
        <div class="policy-row mt-1 mb-1">
          <h5>Third-Party Services</h5>
          <p><b>No Third-Party Services:</b><i>Δίπλωμα Αυτοκινήτου Σήματα ΚΟΚ</i> does not integrate any third-party
            services that collect user data.</p>
        </div>
        <div class="policy-row mt-1 mb-1">
          <h5>Children's Privacy</h5>
          <p><b>No Collection from Children:</b> <i>Δίπλωμα Αυτοκινήτου Σήματα ΚΟΚ</i> is intended for general audiences
            and does not knowingly collect personal information from children under the age of 13.</p>
        </div>
        <div class="policy-row mt-1 mb-1">
          <h5>Changes to this Privacy Policy</h5>
          <p><b>Policy Updates:</b> <i>Δίπλωμα Αυτοκινήτου Σήματα ΚΟΚ</i> reserves the right to update or modify this
            Privacy Policy. Users will be notified of any changes via app notifications or app updates.</p>
        </div>
        <div class="policy-row mt-1 mb-1">
          <h5>Contact Us</h5>
          <p><b>Contact Information:</b> If you have any questions or concerns about this Privacy Policy or <i>Δίπλωμα
              Αυτοκινήτου Σήματα ΚΟΚ</i>, please contact us at <a
              href="mailto:ploumisalexandrosgr@gmail.com">ploumisalexandrosgr@gmail.com</a>.</p>
        </div>
      </div>
      <div class="swiper-div w-full flex relative">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <img class="swiper-slide" :src="require(`@/assets/images/signs1.jpg`)">
            <img class="swiper-slide" :src="require(`@/assets/images/signs2.jpg`)">
            <img class="swiper-slide" :src="require(`@/assets/images/signs3.jpg`)">
            <img class="swiper-slide" :src="require(`@/assets/images/signs4.jpg`)">
            <img class="swiper-slide" :src="require(`@/assets/images/signs5.jpg`)">
            <img class="swiper-slide" :src="require(`@/assets/images/signs6.jpg`)">
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>


import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';


export default {
  name: 'MobileApp',
  mounted() {
    new Swiper('.swiper-container', {
      direction: 'horizontal',
      loop: false,
      spaceBetween: 10,
      breakpoints: {
        1920: {
          slidesPerView: 6,
          spaceBetween: 10
        },
        1366: {
          slidesPerView: 4,
          spaceBetween: 10
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        320: {
          slidesPerView: 2,
          spaceBetween: 10
        }
      }
    });
  }

};
</script>

<style scoped>
.quiz-imgs img {
  height: 440px;
  object-fit: contain;
  border-radius: 20px;

}

.swiper-div {
  overflow: hidden;
}

.swiper-slide {
  border-radius: 20px;
}

.swiper-button-prev,
.swiper-button-next {

  width: 30px;
  height: 30px;
  background-color: #0B0F22;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 20px;

}

.swiper-button-prev {
  left: 10px;
}

.swiper-button-next {
  right: 10px;
}
</style>